<template>
  <div>
    <div id="chart-container12"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
Highcharts3D(Highcharts);
export default {
  name: 'barChartOne',
  props: ['totalData', 'userData', 'chart', 'title', 'searchFilters'],
  data() {
    return {
      recoLines: [],
      unrecoLines: [],
      usernames: []
    };
  },
  mounted() {
    for (let i of this.userData) {
      this.recoLines.push(i.rec_lines);
      this.unrecoLines.push(i.unrec_lines);
      this.usernames.push(i.user_name);
    }
   const chart = Highcharts.chart('chart-container12', {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 20,
          beta: 30,
          viewDistance: 225
        },
        height: 575,
        width: 1400,
        marginLeft: -100,
      },

      xAxis: {
        categories: this.usernames,
        labels: {
          skew3d: true,
          style: {
            fontSize: '16px'
          },
          rotation: -45
        },
        title: {
          text: 'USERS',
          style: {
            fontSize: '40px'
          },
          y: 30,
        }
      },
      title: {
        text: ''
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'LINES',
          style: {
            fontSize: '30px'
          },
          x: -30
        }
      },

      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat:
          '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}'
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          depth: 60
        }
      },

      series: [
        {
          name: 'Reconciled Lines',
          data: this.recoLines,
          stack: 'Asia'
        },
        {
          name: 'Unreconciled Lines',
          data: this.unrecoLines,
          stack: 'Europe'
        }
      ]
    });
    const renderer = chart.renderer;
    renderer.text(`Total Lines: ${this.totalData.lines}`, 850, 40).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Reconciled Lines: ${this.totalData.recLines}`, 850, 60).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const render = chart.renderer;
    render.text(`Unreconciled Lines: ${this.totalData.unrecLines}`, 850, 80).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const legalentity = chart.renderer;
    legalentity.text(`Legal Entity: ${this.searchFilters.leName}`, 20, 480).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const periodfrom = chart.renderer;
    periodfrom.text(`Period From: ${this.searchFilters.period_from}`, 20, 500).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const periodto = chart.renderer;
    periodto.text(`Period To: ${this.searchFilters.period_to}`, 20, 520).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const reversecharge = chart.renderer;
    reversecharge.text(`Reverse Charge: ${this.searchFilters.revCharge}`, 20, 540).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const recoverableflag = chart.renderer;
    recoverableflag.text(`Recoverable Flag: ${this.searchFilters.recCharge}`, 20, 560).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
  }
};
</script>
