import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f8102364&"
import script from "./userDetailsNearMatch.js?vue&type=script&lang=js&"
export * from "./userDetailsNearMatch.js?vue&type=script&lang=js&"
import style0 from "./userDetailsNearMatch.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports