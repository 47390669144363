<template>
  <div>
    <div id="chart-container21"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'areaChartFour',
  props: ['totalData', 'userData', 'title', 'searchFilters'],
  components: {},
  data() {
    return {
      timeClaim: [],
      lateClaim: [],
      usernames: [],
      vendornames: []
    };
  },
  mounted() {
    const seriesData = [];
    seriesData.push(
      {
        name: 'Timely Claimed',
        data: this.timeClaim
      },
      {
        name: 'Late Claimed',
        data: this.lateClaim
      }
    );
    for (let i of this.userData) {
      this.timeClaim.push(i.timely_claimed);
      this.lateClaim.push(i.lately_claimed);
      this.usernames.push(i.user_name);
      this.vendornames.push(i.vendor_name);
    }
    const chart = Highcharts.chart('chart-container21', {
      chart: {
        type: 'spline',
        height: 575,
        width: 1300
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories:
          this.userData[0].user_name !== undefined
            ? this.usernames
            : this.vendornames,
        accessibility: {
          description: ''
        },
        title: {
          text: 'USERS',
          style: {
            fontSize: '20px'
          }
        }
      },
      yAxis: {
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '20px'
          }
        },
        labels: {
          format: '{value}'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
          }
        }
      },
      series: seriesData
    });
    const rendere = chart.renderer;
    rendere
      .text(
        `Timely Claimed: ${this.formattedNumber(this.totalData.timelyclaimed)}`,
        50,
        530
      )
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const render = chart.renderer;
    render
      .text(
        `Late Claimed: ${this.formattedNumber(this.totalData.latelyclaimed)}`,
        50,
        550
      )
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const legalentity = chart.renderer;
    legalentity
      .text(`Legal Entity: ${this.searchFilters.leName}`, 1020, 30)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const periodfrom = chart.renderer;
    periodfrom
      .text(`Period From: ${this.searchFilters.period_from}`, 1020, 50)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const periodto = chart.renderer;
    periodto
      .text(`Period To: ${this.searchFilters.period_to}`, 1020, 70)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const reversecharge = chart.renderer;
    reversecharge
      .text(`Reverse Charge: ${this.searchFilters.revCharge}`, 1020, 90)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const recoverableflag = chart.renderer;
    recoverableflag
      .text(`Recoverable Flag: ${this.searchFilters.recCharge}`, 1020, 110)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
  },
  methods: {
    openAreaCharts() {
      this.showAreaCharts = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
