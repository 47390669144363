import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'performanceMatrix',
  components: { ModelSelect, DatePicker },
  props: ['rowDetails', 'searchFilters'],
  watch: {
    currentPage: function() {
      this.getVendorList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorList();
    }
  },
  data() {
    return {
      loader: false,
      recLines: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      activeTab: 'users',
      vendorPan: null,
      leName: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      revCharge: {
        value: null,
        text: null
      },
      recoFlag: {
        value: null,
        text: null
      },
      name: null,
      periodType: null,
      searchParams: {},
      params: null,
      requestParams: {},
      minimizeFlag: true,
      loading: false,
      showHistoryBtn: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      selectAllCheckBox: false,
      showOpenGstrTwoModal: false,
      startDate: null,
      endDate: null,
      userDetailsData: [],
      userDetailsFields: [
        {
          key: 'tp_gst',
          label: 'TP GST',
          variant: 'success'
        },
        {
          key: 'rtn_prd_gstr1'
        },
        {
          key: 'rtn_prd_gstr3B'
        },
        {
          key: 'dof_gstR1'
        },
        {
          key: 'dof_gstR3B'
        }
      ]
    };
  },
  validations: {
    startDate: {
      required
    },
    endDate: {
      required
    }
  },
  mounted() {
    if (this.searchFilters) {
      (this.leName = {
        value: this.searchFilters.le_id,
        text: this.searchFilters.leName
      }),
        (this.startDate = {
          value: this.searchFilters.gl_date_from,
          text: this.searchFilters.gl_date_from
        }),
        (this.endDate = {
          value: this.searchFilters.gl_date_to,
          text: this.searchFilters.gl_date_to
        }),
        (this.revCharge = {
          value: this.searchFilters.rev_charge,
          text: this.searchFilters.revCharge
        }),
        (this.recoFlag = {
          value: this.searchFilters.recoverable_charge,
          text: this.searchFilters.recCharge
        });
      this.getVendorList();
      this.getParentToChildData(this.searchFilters);
    }
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },

    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading() {
      this.loading = true;
      this.loadingTime = 0;
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.userDetailsData = this.userDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.userDetailsData;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconcile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
      } else {
        this.userDetailsData = this.userDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableValue = 0;
        this.totallines = 0;
        this.totalTax = 0;
        this.recLines = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.userDetailsData) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconcile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },
    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrecLines = unrecLines + i.unrec_lines;
        recLines = recLines + i.rec_lines;
        rectax = rectax + i.reconcile_tax;
        unrectax = unrectax + i.unrec_tax;
        gstn.push(i.first_party_gstn);
        reco.push(Number(i.total_rec_amount_prcnt));
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },

    showTotal() {
      let taxValue = 0;
      let totLines = 0;
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      let recLine = 0;
      for (let i of this.selectedRow) {
        taxValue = taxValue + i.taxable_value;
        totLines = totLines + i.total_lines;
        totTax = totTax + i.total_tax;
        totRec = totRec + i.reconcile_tax;
        totUnrec = totUnrec + i.unrec_tax;
        recLine = recLine + i.rec_lines;
      }

      this.taxableValue = taxValue;
      this.totallines = totLines;
      this.totalTax = totTax;
      this.recLines = recLine;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },

    selectBoxChecked(flag, index, item, cgst) {
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.userDetailsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(el => el.cgst !== cgst);
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getVendorList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_pan: this.vendorPan ? this.vendorPan : this.rowDetails.vendor_pan
      };
      this.loading = true;
      this.taxableAmount = 0;
      this.totalTax = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch(
          'complainceDashboard/getGstPerformanceMatrixVendorDetails',
          payload
        )
        .then(response => {
          if (response.status === 200) {
            this.userDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.userDetailsData) {
              this.taxableAmount = this.taxableAmount + i.taxable_amount;
              this.totalTax = this.totalTax + i.total_tax;
              this.totalReconciled = this.totalReconciled + i.rec_tax;
              this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getParentToChildData(item) {
      (this.leName = {
        value: item.le_id,
        text: item.leName
      }),
        (this.startDate = item.gl_date_from);
      this.endDate = item.gl_date_to;
      (this.revCharge = {
        value: item.rev_charge,
        text: item.revCharge
      }),
        (this.recoFlag = {
          value: item.recoverable_charge,
          text: item.recCharge
        });
    },
    gstrTwoDetailsModal(flag) {
      this.showOpenGstrTwoModal = flag;
    },
    pieChartModal(flag) {
      this.showPieChartModal = flag;
    },
    rowSelected(items) {
      this.rowDetails = items;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.valueType = valueType;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.valueType === 'revCharge') {
          this.revCharge = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if (this.valueType === 'recoFlag') {
          this.recoFlag = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      }
      this.name = item.name;
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType, valueType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.startDate = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.endDate = this.defaultValue;
        }
      } else if (vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (valueType === 'revCharge') {
          this.revCharge = this.defaultValue;
        } else if (valueType === 'recoFlag') {
          this.recoFlag = this.defaultValue;
        }
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.revCharge = this.defaultValue;
      this.recoFlag = this.defaultValue;
      this.startDate = null;
      this.endDate = null;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
    }
  }
};
