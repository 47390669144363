<template>
  <div>
    <div id="chart-container4" @click="openColumnCharts()"></div>
    <b-modal
      v-model="showColumnCharts"
      header-class="modal-color"
      body-class="modal-body-color"
      class="modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="minimizeFlag ? 'xxl' : 'xl'"
      @hidden="chartsModal(false)"
    >
      <template #modal-title>
        TAX
      </template>
      <template>
        <div>
          <columnChartTax
            class="formatChart"
            :totalData="totalData"
            :userData="userData"
            :title="'TAX'"
            :minimizeFlag="minimizeFlag"
            :searchFilters="searchFilters"
          ></columnChartTax>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import columnChartTax from './columnChart/columnChartTax.vue';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import commonHelper from '@/app/utility/common.helper.utility';
Highcharts3D(Highcharts);
export default {
  name: 'columnCharts',
  props: ['totalData', 'userData', 'searchParams'],
  components: {
    columnChartTax
  },
  data() {
    return {
      showColumnCharts: false,
      minimizeFlag: true,
      reconcileTax: [],
      unreconcileTax: [],
      usernames: [],
      searchFilters: {}
    };
  },
  mounted() {
    this.searchFilters = this.searchParams;
    const seriesData = [];
    seriesData.push(
      {
        name: 'Reconciled Tax',
        data: this.reconcileTax,
        stack: 'Asia'
      },
      {
        name: 'Unreconciled Tax',
        data: this.unreconcileTax,
        stack: 'Asia'
      }
    );
    for (let i of this.userData) {
      this.reconcileTax.push(i.reconcile_tax);
      this.unreconcileTax.push(i.unrec_tax);
      this.usernames.push(i.user_name);
    }
  const chart = Highcharts.chart('chart-container4', {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 20,
          beta: 30,
          viewDistance: 25
        },
        height: 500,
        width: 500
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'TAX',
        align: 'left'
      },

      xAxis: {
        title: {
          text: 'USERS',
          style: {
            fontSize: '16px'
          }
        },
        categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
        labels: {
          skew3d: true,
          style: {
            fontSize: '16px'
          }
        }
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '16px'
          }
        }
      },

      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat:
          '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
      },

      plotOptions: {
        series: {},
        column: {
          stacking: 'normal',
          depth: 40
        }
      },
      series: seriesData
    });
    const renderer = chart.renderer;
    renderer.text(`Total Tax: ${this.formattedNumber(this.totalData.tax)}`, 250, 20).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Reconciled Tax: ${this.formattedNumber(this.totalData.reconciled)}`, 250, 40).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const render = chart.renderer;
    render.text(`Unreconciled Tax: ${this.formattedNumber(this.totalData.unreconciled)}`, 250, 60).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
  },
  methods: {
    openColumnCharts() {
      this.showColumnCharts = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
      if (!flag) {
        this.minimizeFlag = true;
      }
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
