import userNearMatch from './userNearMatch';
import itcReconciliation from '../../../../../gstrTwoA/itcReconciliation';
export default {
  name: 'userDetailsNearMatch',
  components: { userNearMatch, itcReconciliation },
  props: [ 'rowDetails', 'leId', 'searchFilters' ],
  data() {
    return {
      showHistoryBtn: false,
      unsubscribe: null,
      loader: false,
      currentPage: 1,
      totalRows: null,
      activeTab: 'userNearMatch',
      gstrDetailsData: [],
      gstrDetailsFields: [
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          variant: 'success',
        },
        {
          key: 'vendor_pan',
          label: 'Vendor Pan',
          class: 'col-fix'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN',
          class: 'col-fix'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number',
          class: 'col-fix'
        },
        {
          key: 'invoice_id',
          label: 'Invoice ID',
          class: 'col-fix'
        },
        {
          key: 'vendor_code',
          label: 'Vendor Code',
          class: 'col-fix'
        },
        {
          key: 'user_name',
          label: 'User Name',
          class: 'col-fix'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix'
        },
        {
          key: 'total_tax',
          label: 'Total Tax',
          class: 'col-fix'
        },
        {
          key: 'total_rec_tax',
          label: 'Reconcile Tax',
          class: 'col-fix'
        },
        {
          key: 'total_unrec_tax',
          label: 'Unreconcile Tax',
          class: 'col-fix'
        },
        {
          key: 'taxable_amt',
          label: 'Taxable Amount',
          class: 'col-fix'
        },
        {
          key: 'rate',
          label: 'Rate',
          class: 'col-fix'
        },
      ],
    };
  },
  mounted() {
    this.gstrDetailsData = [this.rowDetails];
  },
  methods: {
    totalRowsCount(item) {
      this.totalRows = item;
    },
    selectedTab(activeTab) {
      this.totalRows = 0;
      this.activeTab = activeTab;
      this.showHistoryBtn = false;
      if (activeTab === 'userNearMatch') {
        this.inprocessActive = true;
      } else {
        this.inprocessActive = false;
      }
    },
  }
};
