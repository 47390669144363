import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import userDetailsNearMatch from "./userDetailsNearMatch"


export default {
  name: 'userDetailsData',
  components: { ModelSelect, DatePicker, userDetailsNearMatch },
  props: ['rowDetailsData', 'searchFilters'],
  watch: {
    currentPage: function() {
      this.getUserDetailsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserDetailsList();
    }
  },
  data() {
    return {
      loader: false,
      totalTax: 0,
      taxableValue: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      activeTab: 'users',
      leName: {
        value: null,
        text: null
      },
      chart: '',
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      revCharge: {
        value: null,
        text: null
      },
      recoFlag: {
        value: null,
        text: null
      },
      recoStatus : {
        value: '0',
        text: 'UnReconciled'
      },
      selectedNearMatch: {
        value: 'N',
        text: 'No'
      },
      leId: null,
      rowDetails: {},
      selectedRow: [],
      username: null,
      invoicenumber: null,
      vendorname: null,
      vendorpan: null,
      fpgstn: null,
      tpgstn: null,
      periodType: null,
      searchParams: {},
      params: null,
      requestParams: {},
      minimizeFlag: true,
      loading: false,
      showHistoryBtn: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      selectAllCheckBox: false,
      showOpenUserDetailsModal: false,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      selectedGSTNumber: null,
      downloadPayload: null,
      userDetailsDataList: [],
      userDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1',
          width: '50px'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          variant: 'success',
        },
        {
          key: 'vendor_pan',
          label: 'Vendor Pan',
          class: 'col-fix'
        },
        {
          key: 'vendor_gstn',
          label: 'Vendor GSTN',
          class: 'col-fix'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number',
          class: 'col-fix'
        },
        {
          key: 'invoice_id',
          label: 'Invoice ID',
          class: 'col-fix'
        },
        {
          key: 'vendor_code',
          label: 'Vendor Code',
          class: 'col-fix'
        },
        {
          key: 'user_name',
          label: 'User Name',
          class: 'col-fix'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix'
        },
        {
          key: 'total_tax',
          label: 'Total Tax',
          class: 'col-fix'
        },
        {
          key: 'total_rec_tax',
          label: 'Reconcile Tax',
          class: 'col-fix'
        },
        {
          key: 'total_unrec_tax',
          label: 'Unreconcile Tax',
          class: 'col-fix'
        },
        {
          key: 'taxable_amt',
          label: 'Taxable Amount',
          class: 'col-fix'
        },
        {
          key: 'rate',
          label: 'Rate',
          class: 'col-fix'
        },
      ]
    };
  },
  mounted() {
    if (this.searchFilters) {
      this.leName = {
        value: this.searchFilters.le_id,
        text: this.searchFilters.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_from,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_to,
        text: this.searchFilters.selectedPeriodTo
      };
      this.revCharge = {
        value: this.searchFilters.rev_charge,
        text: this.searchFilters.revCharge
      };
      this.recoFlag = {
        value: this.searchFilters.recoverable_charge,
        text: this.searchFilters.recCharge
      };
      this.username = this.rowDetailsData.user_name;
      this.getParentToChildData(this.searchFilters);
      this.getUserDetailsList();
    }
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/performanceMatrixUserDetailsList',
        'user-details-data',
      () => (this.loader = false)
      );
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },

    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading() {
      this.loading = true;
      this.loadingTime = 0;
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.userDetailsDataList = this.userDetailsDataList.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.userDetailsDataList;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_amt;
          this.totalTax = this.totalTax + i.total_tax;
          this.totalReconciled = this.totalReconciled + i.total_rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.total_unrec_tax;
        }
      } else {
        this.userDetailsDataList = this.userDetailsDataList.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableValue = 0;
        this.totalTax = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.userDetailsDataList) {
          this.taxableValue = this.taxableValue + i.taxable_amt;
          this.totalTax = this.totalTax + i.total_tax;
          this.totalReconciled = this.totalReconciled + i.total_rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.total_unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },
    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrectax = unrectax + i.total;
      }

      if (unrecLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },

    showTotal() {
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      let totTaxAmt = 0;
      for (let i of this.selectedRow) {
        totTax = totTax + i.total_tax;
        totRec = totRec + i.total_rec_tax;
        totUnrec = totUnrec + i.total_unrec_tax;
        totTaxAmt = totTaxAmt + i.taxable_amt;
      }
      this.totalTax = totTax;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
      this.taxableValue = totTaxAmt;
    },

    selectBoxChecked(flag, index, item, vendorPan) {
      this.taxableValue = 0;
      this.totalTax = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.userDetailsDataList[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.vendor_pan !== vendorPan
        );
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getUserDetailsList() {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.leName.value,
          period_from: this.selectedPeriodFrom.value,
            period_to: this.selectedPeriodTo.value,
          rev_charge: this.revCharge.value,
          recoverable_charge: this.recoFlag.value,
          user_name: this.rowDetailsData.user_name,
          vendor_name: this.vendorname ? this.vendorname : null,
          vendor_pan: this.vendorpan ? this.vendorpan : null,
          tp_gst: this.tpgstn,
          fp_gst: this.selectedGSTNumber,
          reco_flag_2b: this.recoStatus.value,
          flag: this.selectedNearMatch.value
        };
        this.downloadPayload = payload;
        this.loading = true;
        this.taxableValue = 0;
        this.totalTax = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        this.$store
          .dispatch(
            'complainceDashboard/performanceMatrixUserDetailsList',
            payload
          )
          .then(response => {
            if (response.status === 200) {
              this.userDetailsDataList = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
              for (let i of this.userDetailsDataList) {
                this.taxableValue = this.taxableValue + i.taxable_amt;
                this.totalTax = this.totalTax + i.total_tax;
                this.totalReconciled = this.totalReconciled + i.total_rec_tax;
                this.totalUnreconciled = this.totalUnreconciled + i.total_unrec_tax;
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    getParentToChildData(item) {
      this.leName = {
        value: item.le_id,
        text: item.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_from,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_to,
        text: this.searchFilters.selectedPeriodTo
      };
      this.revCharge = {
        value: item.rev_charge,
        text: item.revCharge
      };
      this.recoFlag = {
        value: item.recoverable_charge,
        text: item.recCharge
      };
      this.username = this.rowDetailsData.user_name;
      this.vendorname = this.rowDetailsData.vendor_name;
      this.vendorpan = this.rowDetailsData.vendor_pan;
    },
    gstrTwoDetailsModal(flag) {
      this.showOpenUserModal = flag;
    },
    pieChartModal(flag) {
      this.showPieChartModal = flag;
    },
    rowSelected(items) {
      this.showOpenUserDetailsModal = true;
      this.rowDetails = items;
      this.leId = this.leName.value;
      this.showGstrNearMatch = true;
      this.searchFilters.leName=this.leName.text;
      this.searchFilters.le_id=this.leName.value;
      this.searchFilters.period_from = this.selectedPeriodFrom.text;
      this.searchFilters.period_to = this.selectedPeriodTo.text;
      this.searchFilters.gst_number = this.selectedGSTNumber;
      this.searchFilters.invoice_num = this.invNum;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    userDetailsModal(flag) {
      this.showOpenUserDetailsModal = flag;
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.valueType = valueType;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.valueType === 'revCharge') {
          this.revCharge = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if (this.valueType === 'recoFlag') {
          this.recoFlag = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if(this.valueType === 'nearMatch') {
          this.selectedNearMatch = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
        if(this.selectedNearMatch.value === 'Y') {
          this.recoStatus = {
            value: '0',
            text: 'UnReconciled'
          };
        } else {
          this.recoStatus = this.defaultValue;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS_NEW
      ) {
        this.recoStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = item.value_set_dtl_id;
      }
      // this.username = item.name;
      // this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType, valueType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (valueType === 'revCharge') {
          this.revCharge = this.defaultValue;
        } else if (valueType === 'recoFlag') {
          this.recoFlag = this.defaultValue;
        }
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      } else if (vsetCode === this.recoStatus.value) {
        this.recoStatus.text = null;
        this.recoStatus.value = null;
      } else if (vsetCode === this.selectedNearMatch.text) {
        this.selectedNearMatch.text = null;
        this.selectedNearMatch.value = null;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = null;
      }
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_lines') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
        if (data === 'rec_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unrec', class2);
          });
        }
      } else {
        if (data === 'total_lines') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
        if (data === 'rec_tax') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unrec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unrec', 'd-total-unrec');
          });
        }
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.revCharge = this.defaultValue;
      this.recoFlag = this.defaultValue;
      this.selectedGSTNumber = null;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.totalRows = null;
      this.selectedNearMatch = this.defaultValue;
      this.recoStatus = this.defaultValue;
      this.searchParams = null;
      this.requestParams = {};
    }
  }
};
