import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'userDetailsNearMatchData',
  components: {},
  watch: {
    currentPage: function() {
      this.getGstrDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstrDetails();
    }
  },
  props: ['rowDetails', 'leId'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOption(),
      vsetCode: null,
      parent_value_set_id: null,
      invoice_id: null,
      dtlId: null,
      reco_flag_2b: null,
      status: null,
      nearMatchdata: [],
      nearMatchFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'vendor_name_pr',
          label: 'Vendor Name',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'vendor_num',
          label: 'Vendor Number',
          class: 'text-center'
        },
        {
          key: 'fp_gstn_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'tp_gstn_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'tp_gstn',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'invoice_no_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'invoice_no_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'date_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'date_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'taxable_value_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'taxable_value_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'total_tax_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'total_tax_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'igst_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'igst_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'cgst_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'cgst_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'sgst_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'sgst_pr',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'rate',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'match_score',
          label: 'Matching Percentage',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'reference_id',
          label: 'Invoice ID',
          class: 'text-center'
        },
        {
          key: 'dtl_id',
          label: 'Detail ID',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'request_id',
          label: 'Request ID',
          class: 'text-center'
        },
      ],
      downloadPayload: null,
    };
  },
  mounted() {
    this.getGstrDetails();
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/performanceMatrixUserDetailsList',
        'user-details-data',
      () => (this.loader = false)
      );
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    selectedRecord(item) {
     this.invoice_id = item.reference_id; 
     this.dtlId = item.dtl_id;
    },
    updateNearMatch() {
      const payload = {
        le_id: this.leId,
        invoice_id: this.invoice_id,
        dtl_id: this.dtlId,
      };
      this.loader = true;
      this.$store
        .dispatch(
          'complainceDashboard/performanceMatrixUserDetailsNearMatchUpdate',
          payload
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getGstrDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leId,
        reference_id: this.rowDetails.invoice_id
      };
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch(
          'complainceDashboard/performanceMatrixUserDetailsNearMatch',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.nearMatchdata = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
