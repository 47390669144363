import users from './users';
import vendors from './vendors';
import legalEntities from './legalEntities';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'performanceMatrix',
  components: { users, vendors, ModelSelect, DatePicker, legalEntities },
  watch: {
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.mainSearch();
    }
  },
  data() {
    return {
      loader: false,
      activeTab: 'users',
      leName: {
        value: null,
        text: null
      },
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      glDateFrom: null,
      glDateTo: null,
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      revCharge: {
        value: 'N',
        text: 'No'
      },
      recoFlag: {
        value: null,
        text: null
      },
      name: null,
      searchParams: {},
      params: null,
      requestParams: {},
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showOpenUserModal &&
          !this.showOpenUserDetailsModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if (this.activeTab === 'users') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/getGstPerformanceMatrixUser',
              'users',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'vendors') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/getGstPerformanceMatrixVendor',
              'vendors',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'legalEntities') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/getGstPerformanceMatrixLegalEntities',
              'legalEntities',
              () => (this.loader = false)
            );
          }
        }
      }
    });
  },
  methods: {
    mainSearch() {
      const loadingFlag = this.$store.state.complainceDashboard.loadingFlag;
      if (!loadingFlag) {
        this.params = null;
        this.getPerformanceMatrixData();
      }
    },
    getPerformanceMatrixData() {
      if (this.activeTab === 'users' || this.activeTab === 'vendors') {
        if (
          this.leName.value !== null &&
          this.selectedPeriodFrom.value !== null &&
          this.selectedPeriodTo.value !== null
        ) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.leName.value,
              period_from: this.selectedPeriodFrom.value,
              period_to: this.selectedPeriodTo.value,
              rev_charge: this.revCharge.value,
              recoverable_charge: this.recoFlag.value
            };
            this.activeTab === 'users'
              ? this.name
                ? (this.requestParams.user_name = this.name)
                : (this.requestParams.user_name = null)
              : this.name
              ? (this.requestParams.vendor_name = this.name)
              : (this.requestParams.vendor_name = null);
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = {
            ...this.requestParams,
            leName: this.leName.text,
            selectedPeriodFrom: this.selectedPeriodFrom.text,
            selectedPeriodTo: this.selectedPeriodTo.text,
            revCharge: this.revCharge.text,
            recCharge: this.recoFlag.text,
            portalName: this.name
          };
        } else {
          alert('Please fill in required fields!');
        }
      } else {
        if (this.glDateFrom !== null && this.glDateTo !== null) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.leName.value,
              period_from: this.glDateFrom,
              period_to: this.glDateTo,
              rev_charge: this.revCharge.value,
              recoverable_charge: this.recoFlag.value
            };
            this.activeTab === 'users'
              ? this.name
                ? (this.requestParams.user_name = this.name)
                : (this.requestParams.user_name = null)
              : this.name
              ? (this.requestParams.vendor_name = this.name)
              : (this.requestParams.vendor_name = null);
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = {
            ...this.requestParams,
            leName: this.leName.text,
            glDateFrom: this.glDateFrom,
            glDateTo: this.glDateTo,
            revCharge: this.revCharge.text,
            recCharge: this.recoFlag.text,
            portalName: this.name
          };
        } else {
          alert('Please fill in required fields!');
        }
      }
    },
    selectedTab(activeTab) {
      this.currentPage = 1;
      this.totalRows = 0;
      this.activeTab = activeTab;
      this.showHistoryBtn = false;
      if (activeTab === 'users') {
        this.inprocessActive = true;
      } else {
        this.inprocessActive = false;
      }
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.valueType === 'revCharge') {
          this.revCharge = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if (this.valueType === 'recoFlag') {
          this.recoFlag = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      this.name = item.name;
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType, valueType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (valueType === 'revCharge') {
          this.revCharge = this.defaultValue;
        } else if (valueType === 'recoFlag') {
          this.recoFlag = this.defaultValue;
        }
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.revCharge = this.defaultValue;
      this.recoFlag = this.defaultValue;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
    }
  }
};
