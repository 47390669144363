<template>
  <div>
    <div id="chart-container8"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import commonHelper from '@/app/utility/common.helper.utility';
Highcharts3D(Highcharts);
export default {
  name: 'lineChartOne',
  props: ['totalData', 'userData', 'title', 'searchFilters'],
  data() {
    return {
      autoReco: [],
      correctionReco: [],
      manualReco: [],
      usernames: [],
      vendornames: []
    };
  },
  mounted() {
    
    for (let i of this.userData) {
      this.autoReco.push(i.auto_reco);
      this.correctionReco.push(i.correction_reco);
      this.manualReco.push(i.manual_reco);
      this.usernames.push(i.user_name);
      this.vendornames.push(i.vendor_name);
    }
    const seriesData = [];
        seriesData.push(
          {
          name: 'Auto Reconcile',
          marker: {
            symbol: 'square'
          },
          data: this.autoReco
        },
        {
          name: 'Correction Reconcile',
          marker: {
            symbol: 'diamond'
          },
          data: this.correctionReco
        },
        {
          name: 'Manual Reconcile',
          marker: {
            symbol: 'square'
          },
          data: this.manualReco
        },
        )
    const chart = Highcharts.chart('chart-container8', {
      chart: {
        type: 'spline',
        height: 575,
        width: 1300
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
        accessibility: {
          description: ''
        },
        title: {
          text: 'USERS',
          style: {
            fontSize: '20px'
          }
        }
      },
      yAxis: {
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '20px'
          }
        },
        labels: {
          format: '{value}'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
          }
        }
      },
      series: seriesData
    });
    const renderer = chart.renderer;
    renderer.text(`Auto Reconciled: ${this.formattedNumber(this.totalData.autoreco)}`, 50, 510).css({
      color: 'black',
      fontSize:'20px'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Correction Reconciled: ${this.formattedNumber(this.totalData.correctionreco)}`, 50, 530).css({
      color: 'black',
      fontSize:'20px'
    }).attr({
      zIndex: 5
    }).add();
    const render = chart.renderer;
    render.text(`Manual Reconciled: ${this.formattedNumber(this.totalData.manualreco)}`, 50, 550).css({
      color: 'black',
      fontSize:'20px'
    }).attr({
      zIndex: 5
    }).add();
    const legalentity = chart.renderer;
    legalentity.text(`Legal Entity: ${this.searchFilters.leName}`, 1020, 30).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const periodfrom = chart.renderer;
    periodfrom.text(`Period From: ${this.searchFilters.period_from}`, 1020, 50).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const periodto = chart.renderer;
    periodto.text(`Period To: ${this.searchFilters.period_to}`, 1020, 70).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const reversecharge = chart.renderer;
    reversecharge.text(`Reverse Charge: ${this.searchFilters.revCharge}`, 1020, 90).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
    const recoverableflag = chart.renderer;
    recoverableflag.text(`Recoverable Flag: ${this.searchFilters.recCharge}`, 1020, 110).css({
      color: 'black',
      fontSize: '20px'
    }).attr({
      zIndex: 5
    }).add();
  }
  ,methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
