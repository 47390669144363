import commonHelper from '@/app/utility/common.helper.utility';
import areaCharts from "../charts/areaCharts";
import lineCharts from "../charts/lineCharts";
import columnCharts from "../charts/columnCharts";
import vendorDetails from "./vendorDetails";


export default {
  name: 'gstrTwo',
  components: {
    areaCharts,
    lineCharts,
    columnCharts,
    vendorDetails
  },
  props: ['searchParams', 'showCharts'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getVendorList(this.searchParams);
      } else {
        this.vendorData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getVendorList(this.searchParams);
    },
    // loading(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.clearLoadingTimeInterval();

    //     if (newValue) {
    //       this.$_loadingTimeInterval = setInterval(() => {
    //         this.loadingTime++;
    //       }, 1000);
    //     }
    //   }
    // },
    // loadingTime(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     if (newValue === this.maxLoadingTime) {
    //       this.loading = false;
    //     }
    //   }
    // }
  },
  created() {
    // this.$_loadingTimeInterval = null;
  },
  data() {
    return {
      // loadingTime: 0,
      // maxLoadingTime: 3,
      taxableValue: 0,
      totallines: 0,
      recLines: 0,
      totalTax: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      autoReco: 0,
      correctionReco: 0,
      manualReco: 0,
      timelyClaimed: 0,
      lateClaimed: 0,
      chartData: [],
      chartData1: [],
      chartData2: [],
      chartData3: [],
      chartData4: [],
      cate: [],
      options: {},
      chart: '',
      status: false,
      selectedRow: [],
      showHistoryBtn: false,
      minimizeFlag: true,
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      selectAllCheckBox: false,
      vendorData: [],
      totalData: {},
      vendorFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          variant: 'success',
        },
        {
          key: 'vendor_pan',
          label: 'Vendor Pan',
          class: 'col-fix'
        },
        {
          key: 'unrec_lines',
          class: 'd-total-line',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'rec_lines',
          class: 'd-total-line',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'total_tax',
          class: 'col-fix'
        },
        {
          key: 'reconcile_tax',
          class: 'col-fix'
        },
        {
          key: 'auto_reco',
          class: 'd-total-rec',
          label: 'Auto Reconcile',
          variant: 'success'
        },
        {
          key: 'correction_reco',
          class: 'd-total-rec',
          label: 'Correction Reconcile',
          variant: 'success'
        },
        {
          key: 'manual_reco',
          class: 'd-total-rec',
          label: 'Manual Reconcile',
          variant: 'success'
        },

        {
          key: 'timely_claimed',
          class: 'col-fix'
        },
        {
          key: 'lately_claimed',
          label: 'Late Claimed',
          class: 'col-fix'
        },
        {
          key: 'unrec_tax',
          label: 'Unreconcile Tax',
          variant: 'danger',
          class: 'col-fix'
        },
        {
          key: 'reco_percent',
          class: 'col-fix'
        }
      ],
      showOpenGstrTwoModal: false,
      showChartsModal: false,
      showOpenVendorModal: false,
      showPieChartModal: false,
      rowDetails: null,
      searchFilters: null
    };
  },
  mounted() {
    this.getVendorList();
    // this.startLoading();
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },

    // clearLoadingTimeInterval() {
    //   clearInterval(this.$_loadingTimeInterval);
    //   this.$_loadingTimeInterval = null;
    // },
    // startLoading() {
    //   this.loading = true;
    //   this.loadingTime = 0;
    // },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.vendorData = this.vendorData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.vendorData;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.autoReco = this.autoReco + i.auto_reco;
          this.correctionReco = this.correctionReco + i.correction_reco;
          this.manualReco = this.manualReco + i.manual_reco;
          this.timelyClaimed = this.timelyClaimed + i.timely_claimed;
          this.latelyClaimed = this.latelyClaimed + i.lately_claimed;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconcile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
      } else {
        this.vendorData = this.vendorData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableValue = 0;
        this.totallines = 0;
        this.autoReco = 0;
        this.correctionReco = 0;
        this.manualReco = 0;
        this.timelyClaimed = 0;
        this.latelyClaimed = 0;
        this.totalTax = 0;
        this.recLines = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.vendorData) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.autoReco = this.autoReco + i.auto_reco;
          this.correctionReco = this.correctionReco + i.correction_reco;
          this.manualReco = this.manualReco + i.manual_reco;
          this.timelyClaimed = this.timelyClaimed + i.timely_claimed;
          this.latelyClaimed = this.latelyClaimed + i.lately_claimed;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconcile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },

    totalLines() {
      this.totalData = {
        tax: this.totalTax,
        reconciled: this.totalReconciled,
        unreconciled: this.totalUnreconciled,
        autoreco: this.autoReco,
        correctionreco: this.correctionReco,
        manualreco: this.manualReco,
        timelyclaimed: this.timelyClaimed,
        latelyclaimed: this.lateClaimed
      };
      this.showChartsModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let autoreco =0;
      let correctionreco = 0;
      let manualreco = 0;
      let timelyclaimed = 0;
      let latelyclaimed = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrecLines = unrecLines + i.unrec_lines;
        recLines = recLines + i.rec_lines;
        rectax = rectax + i.reconcile_tax;
        unrectax = unrectax + i.unrec_tax;
        autoreco = autoreco + i.auto_reco;
        correctionreco = correctionreco + i.correction_reco;
        manualreco = manualreco + i.manual_reco;
        timelyclaimed = timelyclaimed + i.timely_claimed;
        latelyclaimed = latelyclaimed + i.lately_claimed;
        gstn.push(i.first_party_gstn);
        reco.push(Number(i.total_rec_amount_prcnt));
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.chartData3 = [
        ['Auto Reconciled', autoreco],
        ['Correction Reconciled', correctionreco],
        ['Manual Reconciled', manualreco]
      ],
      this.chartData4 = [
        ['Timely Claimed', timelyclaimed],
        ['Lately Claimed', latelyclaimed]
      ],
      this.cate = gstn;
      this.chartData2 = reco;
    },

    showTotal() {
      let taxValue = 0;
      let totLines = 0;
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      let recLine = 0;
      let autoreco =0;
      let correctionreco = 0;
      let manualreco = 0;
      let timelyclaimed = 0;
      let latelyclaimed = 0;
      for (let i of this.selectedRow) {
        taxValue = taxValue + i.taxable_value;
        totLines = totLines + i.total_lines;
        totTax = totTax + i.total_tax;
        totRec = totRec + i.reconcile_tax;
        totUnrec = totUnrec + i.unrec_tax;
        recLine = recLine + i.rec_lines;
        autoreco = autoreco + i.auto_reco;
        correctionreco = correctionreco + i.correction_reco;
        manualreco = manualreco + i.manual_reco;
        timelyclaimed = timelyclaimed + i.timely_claimed;
        latelyclaimed = latelyclaimed + i.lately_claimed;
      }

      this.taxableValue = taxValue;
      this.totallines = totLines;
      this.totalTax = totTax;
      this.recLines = recLine;
      this.autoReco = autoreco;
      this.correctionReco = correctionreco;
      this.manualReco = manualreco;
      this.timelyClaimed = timelyclaimed;
      this.latelyClaimed = latelyclaimed;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },

    selectBoxChecked(flag, index, item, vendorpan) {
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.autoReco = 0;
        this.correctionReco = 0;
        this.manualReco = 0;
        this.timelyClaimed = 0;
        this.latelyClaimed = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.vendorData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(el => el.vendor_pan !== vendorpan);
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getVendorList(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loading = true;
      this.$store.commit('complainceDashboard/setLoadingFlag', this.loading);
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.autoReco = 0;
        this.correctionReco = 0;
        this.manualReco = 0;
        this.timelyClaimed = 0;
        this.latelyClaimed = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch('complainceDashboard/getGstPerformanceMatrixVendor', payload)
        .then(response => {
          if (response.status === 200) {
            this.vendorData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.vendorData) {
                this.taxableValue = this.taxableValue + i.taxable_value;
                this.totallines = this.totallines + i.total_lines;
                this.totalTax = this.totalTax + i.total_tax;
                this.recLines = this.recLines + i.rec_lines;
                this.autoReco = this.autoReco + i.auto_reco;
            this.correctionReco = this.correctionReco + i.correction_reco;
            this.manualReco = this.manualReco + i.manual_reco;
            this.timelyClaimed = this.timelyClaimed + i.timely_claimed;
            this.latelyClaimed = this.latelyClaimed + i.lately_claimed;
                this.totalReconciled = this.totalReconciled + i.reconcile_tax;
                this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
              }
          }
          this.loading = false;
          this.$store.commit('complainceDashboard/setLoadingFlag', this.loading);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_lines') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
        if (data === 'reconcile_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unrec', class2);
          });
        }
      } else {
        if (data === 'total_lines') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
        if (data === 'reconcile_tax') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unrec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unrec', 'd-total-unrec');
          });
        }
      }
    },
    vendorModal(flag) {
      this.showOpenVendorModal = flag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
    },
    rowSelected(items) {
      this.showOpenVendorModal = true;
      this.rowDetails = items;
      this.searchFilters = this.searchParams;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  }
};
