<template>
  <div>
    <div id="chart-container1" @click="openPieCharts()"></div>
    <b-modal
      v-model="showPieCharts"
      header-class="modal-color"
      body-class="modal-body-color"
      class="modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="minimizeFlag ? 'xxl' : 'xl'"
      @hidden="chartsModal(false)"
    >
      <template #modal-title>
        LINES
      </template>
      <template>
        <div>
          <barChartOne
              :totalData="totalData"
              :userData="userData"
              :chart="chart"
              :title="'RECONCILED LINES'"
              :searchFilters="searchFilters"
            ></barChartOne>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import barChartOne from "./barChart/barChartOne.vue";
Highcharts3D(Highcharts);
export default {
  name: 'barCharts',
  props: ['totalData', 'userData', 'chart', 'chart1', 'chart3', 'chart4', 'searchParams'],
  components: {
    barChartOne,
  },
  data() {
    return {
      showPieCharts: false,
      minimizeFlag: true,
       recoLines: [],
      unrecoLines: [],
      usernames: [],
      searchFilters: {}
    };
  },
  mounted() {
    this.searchFilters = this.searchParams;
    for (let i of this.userData) {
      this.recoLines.push(i.rec_lines);
      this.unrecoLines.push(i.unrec_lines);
      this.usernames.push(i.user_name);
    }
    const chart = Highcharts.chart('chart-container1', {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 20,
          beta: 30,
          viewDistance: 25
        },
        height: 500,
        width: 500
      },

      xAxis: {
        categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
        labels: {
          skew3d: true,
          style: {
            fontSize: '16px'
          }
        },
        title: {
          text: 'USERS',
          style: {
            fontSize: '16px'
          }
        }
      },
      title: {
        text: 'LINES',
        align: 'left'
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'LINES',
          style: {
            fontSize: '16px'
          }
        }
      },

      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat:
          '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}'
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          depth: 40
        }
      },

      series: [
        {
          name: 'Reconciled Lines',
          data: this.recoLines,
          stack: 'Asia'
        },
        {
          name: 'Unreconciled Lines',
          data: this.unrecoLines,
          stack: 'Europe'
        }
      ]
    });
    const renderer = chart.renderer;
    renderer.text(`Total Lines: ${this.totalData.lines}`, 250, 20).css({
      color: 'black',
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Rec Lines: ${this.totalData.recLines}`, 250, 40).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const render = chart.renderer;
    render.text(`Rec Lines: ${this.totalData.unrecLines}`, 250, 60).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
  },
  methods: {
    openPieCharts() {
      this.showPieCharts = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
    }
  }
};
</script>
