<template>
  <div>
    <div id="chart-container3" @click="openLineCharts()"></div>
    <b-modal
      v-model="showLineCharts"
      header-class="modal-color"
      body-class="modal-body-color"
      class="modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="minimizeFlag ? 'xxl' : 'xl'"
      @hidden="chartsModal(false)"
    >
      <template #modal-title>
        RECONCILED TAX
      </template>
      <template>
        <div>
          <lineChartOne
              :totalData="totalData"
              :userData="userData"
              :title="'Reconcile Tax'"
              :searchFilters="searchFilters"
            ></lineChartOne>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import lineChartOne from './lineChart/lineChartOne.vue';
import commonHelper from '@/app/utility/common.helper.utility';
Highcharts3D(Highcharts);
export default {
  name: 'lineCharts',
  props: ['totalData', 'userData', 'searchParams'],
  components: {
    lineChartOne,
  },
  data() {
    return {
      showLineCharts: false,
      minimizeFlag: true,
      autoReco: [],
      correctionReco: [],
      manualReco: [],
      usernames: [],
      searchFilters: {}
    };
  },
  mounted() {
    this.searchFilters = this.searchParams;
    for (let i of this.userData) {
      this.autoReco.push(i.auto_reco);
      this.correctionReco.push(i.correction_reco);
      this.manualReco.push(i.manual_reco);
      this.usernames.push(i.user_name);
    }
    const seriesData = [];
        seriesData.push(
          {
          name: 'Auto Reconcile',
          marker: {
            symbol: 'square'
          },
          data: this.autoReco
        },
        {
          name: 'Correction Reconcile',
          marker: {
            symbol: 'diamond'
          },
          data: this.correctionReco
        },
        {
          name: 'Manual Reconcile',
          marker: {
            symbol: 'square'
          },
          data: this.manualReco
        },
        )
    const chart = Highcharts.chart('chart-container3', {
      chart: {
        type: 'spline',
        height: 500
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'RECONCILED TAX',
        align: 'left'
      },
      xAxis: {
        title: {
          text: 'USERS',
          style: {
            fontSize: '16px'
          }
        },
        categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
        accessibility: {
          description: 'Users'
        }
      },
      yAxis: {
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          format: '{value}'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
          }
        }
      },
      series: seriesData
    });
    const renderer = chart.renderer;
    renderer.text(`Auto Reconciled: ${this.formattedNumber(this.totalData.autoreco)}`, 250, 20).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Correction Reconciled: ${this.formattedNumber(this.totalData.correctionreco)}`, 250, 40).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const render = chart.renderer;
    render.text(`Manual Reconciled: ${this.formattedNumber(this.totalData.manualreco)}`, 250, 60).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
  },
  methods: {
    openLineCharts() {
      this.showLineCharts = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
