<template>
  <div>
    <div id="chart-container2" @click="openAreaCharts()"></div>
    <b-modal
      v-model="showAreaCharts"
      header-class="modal-color"
      body-class="modal-body-color"
      class="modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="minimizeFlag ? 'xxl' : 'xl'"
      @hidden="chartsModal(false)"
    >
      <template #modal-title>
       CLAIMED TAX
      </template>
      <template>
        <div>
            <areaChartFour
              :totalData="totalData"
              :userData="userData"
              :title="'CLAIMED TAX'"
              :searchFilters="searchFilters"
            ></areaChartFour>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import areaChartFour from "./areaChart/areaChartFour.vue";
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'areaCharts',
  props: ['totalData', 'userData', 'searchParams'],
  components: {
    areaChartFour
  },
  data() {
    return {
      showAreaCharts: false,
      minimizeFlag: true,
      timeClaim: [],
      lateClaim: [],
      usernames: [],
      searchFilters: {}
    };
  },
  mounted() {
    this.searchFilters = this.searchParams;
    const seriesData = [];
        seriesData.push(
          {
            name: 'Timely Claimed',
            data: this.timeClaim,
          },
          {
            name: 'Late Claimed',
            data: this.lateClaim,
          }
        )
    for (let i of this.userData) {
      this.timeClaim.push(i.timely_claimed);
      this.lateClaim.push(i.lately_claimed);
      this.usernames.push(i.user_name);
    }
    // const chart = Highcharts.chart('chart-container2', {
    //   chart: {
    //     type: 'area',
    //     height: 500,
    //     width: 500
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   accessibility: {
    //     description: ''
    //   },
    //   title: {
    //     text: 'CLAIMED TAX',
    //     align: 'left'
    //   },
    //   xAxis: {
    //     categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
    //     allowDecimals: false,
    //     accessibility: {
    //       rangeDescription: ''
    //     },
    //     title: {
    //       text: 'USERS',
    //       style: {
    //         fontSize: '16px'
    //       }
    //     }
    //   },
    //   yAxis: {
    //     title: {
    //       text: 'RUPEES',
    //       style: {
    //         fontSize: '16px'
    //       }
    //     }
    //   },
    //   tooltip: {
    //     pointFormat:
    //       '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
    //   },
    //   plotOptions: {
    //     area: {
    //       marker: {
    //         enabled: false,
    //         symbol: 'circle',
    //         radius: 2,
    //         states: {
    //           hover: {
    //             enabled: true
    //           }
    //         }
    //       }
    //     }
    //   },
    //   series: seriesData
    // });
    const chart = Highcharts.chart('chart-container2', {
      chart: {
        type: 'spline',
        height: 500
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'CLAIMED TAX',
        align: 'left'
      },
      xAxis: {
        title: {
          text: 'USERS',
          style: {
            fontSize: '16px'
          }
        },
        categories: this.userData[0].user_name !== undefined ? this.usernames : this.vendornames,
        accessibility: {
          description: 'Users'
        }
      },
      yAxis: {
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          format: '{value}'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
          }
        }
      },
      series: seriesData
    });
    const renderer = chart.renderer;
    renderer.text(`Timely Claimed: ${this.formattedNumber(this.totalData.timelyclaimed)}`, 250, 20).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text(`Lately Claimed: ${this.formattedNumber(this.totalData.latelyclaimed)}`, 250, 40).css({
      color: 'black'
    }).attr({
      zIndex: 5
    }).add();
  },
  methods: {
    openAreaCharts() {
      this.showAreaCharts = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    chartsModal(flag) {
      this.showChartsModal = flag;
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
