import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'legalEntities',
  components: {},
  props: ['searchParams', 'showCharts'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getLegalEntitiesList(this.searchParams);
      } else {
        this.legalEntitiesData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getLegalEntitiesList(this.searchParams);
    },
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    }
  },
  created() {
    this.$_loadingTimeInterval = null;
  },
  data() {
    return {
      loadingTime: 0,
      maxLoadingTime: 3,
      taxableValue: 0,
      totallines: 0,
      recLines: 0,
      totalTax: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      chartData: [],
      chartData1: [],
      chartData2: [],
      cate: [],
      options: {},
      chart: '',
      status: false,
      selectedRow: [],
      showHistoryBtn: false,
      minimizeFlag: true,
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      selectAllCheckBox: false,
      legalEntitiesData: [],
      legalEntitiesFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'col col-sm-1 stickyColumn'
        },
        {
          key: 'org_name',
          class: 'col-fix',
          variant: 'success'
        },
        {
          key: 'taxable_value',
          class: 'col-fix'
        },
        {
          key: 'total_tax',
          class: 'col-fix'
        },
        {
          key: 'reconile_tax',
          class: 'col-fix'
        },
        {
          key: 'unrec_tax',
          label: 'Unreconcile Tax',
          variant: 'danger',
          class: 'col-fix'
        },
        {
          key: 'reco_percent',
          class: 'col-fix'
        }
      ],
      showOpenGstrTwoModal: false,
      showPieChartModal: false,
      rowDetails: null,
      searchFilters: null
    };
  },
  mounted() {
    this.getLegalEntitiesList();
    this.startLoading();
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },

    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading() {
      this.loading = true;
      this.loadingTime = 0;
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.legalEntitiesData = this.legalEntitiesData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.legalEntitiesData;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
      } else {
        this.legalEntitiesData = this.legalEntitiesData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableValue = 0;
        this.totallines = 0;
        this.totalTax = 0;
        this.recLines = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.legalEntitiesData) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.reconile_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },

    // checkUncheckAll() {
    //   // if (this.selectAllCheckBox) {
    //   //   this.selectedRow= this.legalEntitiesData;
    //   // }
    //   this.selectedRow=[];
    //   this.legalEntitiesData = this.legalEntitiesData.map((data, index) => {

    //     data.selectBox = this.selectAllCheckBox;
    //     if (this.selectAllCheckBox) {
    //       this.selectBoxChecked(data.selectBox, index);
    //     }
    //     return data;
    //   });

    // },

    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrecLines = unrecLines + i.unrec_lines;
        recLines = recLines + i.rec_lines;
        rectax = rectax + i.reconile_tax;
        unrectax = unrectax + i.unrec_tax;
        gstn.push(i.first_party_gstn);
        reco.push(Number(i.total_rec_amount_prcnt));
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },

    showTotal() {
      let taxValue = 0;
      let totLines = 0;
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      let recLine = 0;
      for (let i of this.selectedRow) {
        taxValue = taxValue + i.taxable_value;
        totLines = totLines + i.total_lines;
        totTax = totTax + i.total_tax;
        totRec = totRec + i.reconile_tax;
        totUnrec = totUnrec + i.unrec_tax;
        recLine = recLine + i.rec_lines;
      }

      this.taxableValue = taxValue;
      this.totallines = totLines;
      this.totalTax = totTax;
      this.recLines = recLine;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },

    selectBoxChecked(flag, index, item, leid) {
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.legalEntitiesData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(el => el.le_id !== leid);
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getLegalEntitiesList(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loading = true;
      this.$store.commit('complainceDashboard/setLoadingFlag', this.loading);
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.$store
        .dispatch(
          'complainceDashboard/getGstPerformanceMatrixLegalEntities',
          payload
        )
        .then(response => {
          if (response.status === 200) {
            this.legalEntitiesData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            for (let i of this.legalEntitiesData) {
                this.taxableValue = this.taxableValue + i.taxable_value;
                this.totallines = this.totallines + i.total_lines;
                this.totalTax = this.totalTax + i.total_tax;
                this.recLines = this.recLines + i.rec_lines;
                this.totalReconciled = this.totalReconciled + i.reconile_tax;
                this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
            }
          }
          this.loading = false;
          this.$store.commit('complainceDashboard/setLoadingFlag', this.loading);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_lines') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
        if (data === 'reconile_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unrec', class2);
          });
        }
      } else {
        if (data === 'total_lines') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
        if (data === 'reconile_tax') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unrec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unrec', 'd-total-unrec');
          });
        }
      }
    },
    legalEntitiesModal(flag) {
      this.showOpenGstrTwoModal = flag;
    },
    pieChartModal(flag) {
      this.showPieChartModal = flag;
    },
    rowSelected(items) {
      this.showOpenGstrTwoModal = true;
      this.rowDetails = items;
      this.searchFilters = this.searchParams;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  }
};
