import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import userDetailsData from "./userDetailsData";

export default {
  name: 'userDetails',
  components: { ModelSelect, DatePicker, userDetailsData },
  props: ['rowDetails', 'searchFilters'],
  watch: {
    currentPage: function() {
      this.getUserList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserList();
    }
  },
  data() {
    return {
      loader: false,
      totalTax: 0,
      totalReconciled: 0,
      totalUnreconciled: 0,
      activeTab: 'users',
      leName: {
        value: null,
        text: null
      },
      chart: '',
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      revCharge: {
        value: null,
        text: null
      },
      recoFlag: {
        value: null,
        text: null
      },
      selectedRow: [],
      username: null,
      periodType: null,
      searchParams: {},
      params: null,
      requestParams: {},
      rowDetailsData: {},
      minimizeFlag: true,
      loading: false,
      showHistoryBtn: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      selectAllCheckBox: false,
      showOpenUserDetailsModal: false,
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      userDetailsData: [],
      userDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1',
          width: '50px'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          variant: 'success',
          class: 'col-fix'
        },
        {
          key: 'vendor_pan',
          label: 'Vendor Pan',
          class: 'col-fix'
        },
        {
          key: 'unrec_lines',
          label:'Unreconciled Lines',
          class: 'col-fix'
        },
        {
          key: 'taxable_amount',
          class: 'col-fix'
        },
        {
          key: 'total_tax',
          class: 'col-fix'
        },
        {
          key: 'rec_tax',
          label: 'Reco Tax',
          class: 'col-fix'
        },
        {
          key: 'rec_igst',
          class: 'd-total-rec col-fix',
          label: 'Rec IGST',
          variant: 'success'
        },
        {
          key: 'rec_cgst',
          class: 'd-total-rec col-fix',
          label: 'Rec CGST',
          variant: 'success'
        },
        {
          key: 'rec_sgst',
          class: 'd-total-rec col-fix',
          label: 'Rec SGST',
          variant: 'success'
        },
        {
          key: 'unrec_tax',
          label: 'Unreco Tax',
          class: 'col-fix'
        },
        {
          key: 'unrec_igst',
          label: 'Unrec IGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
        {
          key: 'unrec_cgst',
          label: 'Unrec CGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
        {
          key: 'unrec_sgst',
          label: 'Unrec SGST',
          class: 'd-total-unrec',
          variant: 'success'
        },
      ],
      downloadPayload: null
    };
  },
  validations: {
    leName: {
      text: { required }
    },
    selectedPeriodFrom: {
      value: { required }
    },
    selectedPeriodTo: {
      value: { required }
    },
    username: {
      required
    }
  },
  mounted() {
    if (this.searchFilters) {
      this.leName = {
        value: this.searchFilters.le_id,
        text: this.searchFilters.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_from,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_to,
        text: this.searchFilters.selectedPeriodTo
      };
      this.revCharge = {
        value: this.searchFilters.rev_charge,
        text: this.searchFilters.revCharge
      };
      this.recoFlag = {
        value: this.searchFilters.recoverable_charge,
        text: this.searchFilters.recCharge
      };
      this.username = this.rowDetails.user_name;
      this.getParentToChildData(this.searchFilters);
      this.getUserList();
    }
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/getGstPerformanceMatrixUserDetails',
        'user-details',
      () => (this.loader = false)
      );
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },

    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading() {
      this.loading = true;
      this.loadingTime = 0;
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.userDetailsData = this.userDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.userDetailsData;
        for (let i of this.selectedRow) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
      } else {
        this.userDetailsData = this.userDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.taxableValue = 0;
        this.totallines = 0;
        this.totalTax = 0;
        this.recLines = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        for (let i of this.userDetailsData) {
          this.taxableValue = this.taxableValue + i.taxable_value;
          this.totallines = this.totallines + i.total_lines;
          this.totalTax = this.totalTax + i.total_tax;
          this.recLines = this.recLines + i.rec_lines;
          this.totalReconciled = this.totalReconciled + i.rec_tax;
          this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
        }
        this.showHistoryBtn = false;
      }
    },
    totalLines() {
      this.showPieChartModal = true;
      this.chart = 'SHOW CHARTS';
      let unrecLines = 0;
      let recLines = 0;
      let rectax = 0;
      let unrectax = 0;
      let gstn = [];
      let reco = [];
      for (let i of this.selectedRow) {
        unrectax = unrectax + i.total;
      }

      if (unrecLines === 0 && recLines === 0) {
        this.showHistoryBtn = false;
      }
      this.chartData = [
        ['Reconciled Lines', recLines],
        ['Unreconciled Lines', unrecLines]
      ];
      this.chartData1 = [
        ['Total Reconciled', rectax],
        ['Total Unreconciled', unrectax]
      ];
      this.cate = gstn;
      this.chartData2 = reco;
    },

    showTotal() {
      let totTax = 0;
      let totRec = 0;
      let totUnrec = 0;
      for (let i of this.selectedRow) {
        totTax = totTax + i.total_tax;
        totRec = totRec + i.rec_tax;
        totUnrec = totUnrec + i.unrec_tax;
      }
      this.totalTax = totTax;
      this.totalReconciled = totRec;
      this.totalUnreconciled = totUnrec;
    },

    selectBoxChecked(flag, index, item, vendorPan) {
      this.taxableValue = 0;
      this.totallines = 0;
      this.totalTax = 0;
      this.recLines = 0;
      this.totalReconciled = 0;
      this.totalUnreconciled = 0;
      this.userDetailsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.vendor_pan !== vendorPan
        );
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    getUserList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.leName.value,
          period_from: this.selectedPeriodFrom.value,
            period_to: this.selectedPeriodTo.value,
          rev_charge: this.revCharge.value,
          recoverable_charge: this.recoFlag.value,
          user_name: this.username ? this.username : this.rowDetails.user_name
        };
        this.downloadPayload = payload;
        this.loading = true;
        this.taxableAmount = 0;
        this.totalTax = 0;
        this.totalReconciled = 0;
        this.totalUnreconciled = 0;
        this.$store
          .dispatch(
            'complainceDashboard/getGstPerformanceMatrixUserDetails',
            payload
          )
          .then(response => {
            if (response.status === 200) {
              this.userDetailsData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
              for (let i of this.userDetailsData) {
                this.taxableAmount = this.taxableAmount + i.taxable_amount;
                this.totalTax = this.totalTax + i.total_tax;
                this.totalReconciled = this.totalReconciled + i.rec_tax;
                this.totalUnreconciled = this.totalUnreconciled + i.unrec_tax;
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getParentToChildData(item) {
      this.leName = {
        value: item.le_id,
        text: item.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_from,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_to,
        text: this.searchFilters.selectedPeriodTo
      };
      this.revCharge = {
        value: item.rev_charge,
        text: item.revCharge
      };
      this.recoFlag = {
        value: item.recoverable_charge,
        text: item.recCharge
      };
      this.username = this.rowDetails.user_name;
    },
    gstrTwoDetailsModal(flag) {
      this.showOpenUserModal = flag;
    },
    pieChartModal(flag) {
      this.showPieChartModal = flag;
    },
    rowSelected(items) {
      this.showOpenUserDetailsModal = true;
      this.rowDetailsData = items;
      this.searchParams = this.searchFilters;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    userDetailsModal(flag) {
      this.showOpenUserDetailsModal = flag;
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.valueType = valueType;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.valueType === 'revCharge') {
          this.revCharge = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if (this.valueType === 'recoFlag') {
          this.recoFlag = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      this.username = item.name;
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType, valueType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (valueType === 'revCharge') {
          this.revCharge = this.defaultValue;
        } else if (valueType === 'recoFlag') {
          this.recoFlag = this.defaultValue;
        }
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        }
      }
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'total_lines') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
        if (data === 'rec_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-rec', class2);
          });
        } else {
          elem.forEach(el => {
            el.classList.replace('d-total-unrec', class2);
          });
        }
      } else {
        if (data === 'total_lines') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
        if (data === 'rec_tax') {
          let otherElem = document.querySelectorAll('.other-total-rec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-rec', 'd-total-rec');
          });
        } else {
          let otherElem = document.querySelectorAll('.other-total-unrec');
          otherElem.forEach(el => {
            el.classList.replace('other-total-unrec', 'd-total-unrec');
          });
        }
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.revCharge = this.defaultValue;
      this.recoFlag = this.defaultValue;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
    }
  }
};
