<template>
  <div>
    <div id="chart-container6"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import commonHelper from '@/app/utility/common.helper.utility';
Highcharts3D(Highcharts);
export default {
  name: 'columnChartTax',
  props: ['totalData', 'userData', 'title', 'minimizeFlag', 'searchFilters'],
  data() {
    return {
      reconcileTax: [],
      unreconcileTax: [],
      usernames: [],
      vendornames: []
    };
  },
  mounted() {
    const seriesData = [];
    seriesData.push(
      {
        name: 'Reconciled Tax',
        data: this.reconcileTax,
        stack: 'Asia'
      },
      {
        name: 'Unreconciled Tax',
        data: this.unreconcileTax,
        stack: 'Asia'
      }
    );
    for (let i of this.userData) {
      this.reconcileTax.push(i.reconcile_tax);
      this.unreconcileTax.push(i.unrec_tax);
      this.usernames.push(i.user_name);
      this.vendornames.push(i.vendor_name);
    }
    const chart = Highcharts.chart('chart-container6', {
      chart: {
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 20,
          beta: 30,
          viewDistance: 225
        },
        width: 1400,
        height: 575,
        marginLeft: -100,
      },
      credits: {
        enabled: false
      },
      title: {
        text: '',
        align: 'center'
      },

      xAxis: {
        title: {
          text: 'USERS',
          style: {
            fontSize: '40px'
          },
          y: 60
        },
        categories:
          this.userData[0].user_name !== undefined
            ? this.usernames
            : this.vendornames,
        labels: {
          useHTML: true,
          skew3d: true,
          style: {
            fontSize: '10px'
          },
          rotation: -45
        }
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'RUPEES',
          style: {
            fontSize: '30px'
          },
          x: -40,
        }
      },

      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat:
          '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
      },

      plotOptions: {
        series: {},
        column: {
          stacking: 'normal',
          depth: 60
        }
      },
      series: seriesData
    });
    const renderer = chart.renderer;
    renderer
      .text(`Total Tax: ${this.formattedNumber(this.totalData.tax)}`, 850, 40)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const rendere = chart.renderer;
    rendere
      .text(
        `Reconciled Tax: ${this.formattedNumber(this.totalData.reconciled)}`,
        850,
        60
      )
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const render = chart.renderer;
    render
      .text(
        `Unreconciled Tax: ${this.formattedNumber(
          this.totalData.unreconciled
        )}`,
        850,
        80
      )
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const legalentity = chart.renderer;
    legalentity
      .text(`Legal Entity: ${this.searchFilters.leName}`, 20, 480)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const periodfrom = chart.renderer;
    periodfrom
      .text(`Period From: ${this.searchFilters.period_from}`, 20, 500)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const periodto = chart.renderer;
    periodto
      .text(`Period To: ${this.searchFilters.period_to}`, 20, 520)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const reversecharge = chart.renderer;
    reversecharge
      .text(`Reverse Charge: ${this.searchFilters.revCharge}`, 20, 540)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
    const recoverableflag = chart.renderer;
    recoverableflag
      .text(`Recoverable Flag: ${this.searchFilters.recCharge}`, 20, 560)
      .css({
        color: 'black',
        fontSize: '20px'
      })
      .attr({
        zIndex: 5
      })
      .add();
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    }
  }
};
</script>
